import React, { useRef, useEffect, useState } from 'react'
import config from '../utils/config'
import Home from './home'
import Home2 from './homeStyle2'
const Index = () => {

    return (
        <>
            {
                config.template === 'style2' ? <Home2 /> : <Home />
            }
        </>
    )
}

export default Index
