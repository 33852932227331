import React,{useState} from 'react'
import { Modal,Form, Input,message } from 'antd';
import config from "../../utils/config";
const Page = (props) => {
    let logoName=config.logoName || 'logo'

    const [form] = Form.useForm();
    const [isModalVisible,setModalVisible]=useState(false);
    const handleOk=()=>{
        form.validateFields().then(value=>{
            setModalVisible(false);
            message.success('Submitted successfully,we will process your request later ', 10)
        }).catch(err=>{
            console.log(err,'err')
        })

    }
    const handleCancel=()=>{
        setModalVisible(false)
    }

    return (
        <div className="page pad-lg pt40 pb60">
            <Modal title={
                [<div className="modal-title">Submit a Request<span>Modify Delete Data</span></div>]
            } cancelText="Cancel" okText="Submit" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form  colon={false} name="normal_login" className="login-form" form={form} >
                     <Form.Item label="Contact Information" name="contact" rules={[{ required: true, message: 'Please enter your contact information' }]}>
                       <Input placeholder="Please enter your contact information" />
                     </Form.Item>
                     <Form.Item label={<p>Requirement <br /> Description</p>} rules={[{ required: true, message: 'Please enter your request' }]}  name="demand">
                       <Input.TextArea  placeholder="Please enter your request (e.g delete my data)" />
                     </Form.Item>
                 </Form>
            </Modal>
            <div className="wrapper">
                <div className="logo">
                    <img src={require(`@/assets/images/${logoName}.png`).default} alt="" />
                </div>
                <div className="title fz40">Privacy Policy</div>
            </div>
            <div className="f-line"></div>

            <div className="wrap fz24 lh40 mt40">
                <p>We take the privacy and protection of personal information of our users very seriously. We understand the importance of personal information to you and will do our best to protect the security of your personal information. When you use our products or services, we may collect and use information about you. Through our Privacy Policy ("this Privacy Policy"), we want to explain to you how we collect, use, and protect this information when you use our products or services.</p>
                <p>We may offer different products or services, and if a separate product or service has a separate privacy policy, the privacy policy for that product and service will apply first. Anything not covered by that product and service's privacy policy will be governed by the contents of this privacy policy.</p>
                <p>This Privacy Policy does not apply to services provided to you by other independent third parties, for example, if a third party on our platform relies on our platform to provide services to you, the personal information you provide to the third party does not apply to this Privacy Policy and we are not responsible for any third party's use of the information provided by you.</p>
                <p>Please read and thoroughly understand this Privacy Policy before using our products or services, and use the relevant products or services after confirming that you fully understand and agree to it. If you do not agree to any of the contents of this Privacy Policy, you should immediately stop using our platform services. By using our products or services, you acknowledge that you have fully understood and agreed to the content of this policy.</p>
                <p className="bold">Part I. Definitions</p>
                <p>You: The term "you" in this Agreement refers specifically to the user of the product or service.</p>
                <p>Personal Information: Personal information for the purposes of this Agreement means any information recorded electronically or otherwise that, alone or in combination with other information, can identify a specific natural person or reflect the activities of a specific natural person. The personal information covered by this Privacy Policy includes: basic information (including personal birthday, gender, e-mail address); personal image screen; network identification information (including system account, IP address, e-mail address and password, password protection answer related to the foregoing); personal property information (transaction and consumption records and virtual property information); address book; personal Internet records (including website (including website browsing records, software usage records, click records); personal common device information (including hardware model, device MAC address, operating system type,software list unique device identification code (including IMEI/androidID/IDFA/OPENUDID/GUID, SIM card IMSI information, including information describing the basic situation of personal common devices).</p>

                <p className="bold">Part II Privacy Policy</p>
                <p>This policy will help you understand the following.</p>
                <p className="fw400">I. How we collect your personal information</p>
                <p className="fw400">II. how we use your personal information</p>
                <p className="fw400">III. how we entrust the processing, sharing, transfer, public disclosure of your personal information</p>
                <p className="fw400">IV. how we store and protect your personal information</p>
                <p className="fw400">V. The use of cookies</p>
                <p className="fw400">VI. Access, modification and deletion of information</p>
                <p className="fw400">VII. How we handle personal information of minors</p>
                <p className="fw400">VIII. Privacy Policy, Special Terms</p>
                <p className="fw400">IX. Updates to the Privacy Policy</p>
                <p className="fw400">X. How to contact us</p>

                <p className="bold">I. How we collect your information</p>
                <p>We collect and use your personal information in accordance with the principles of lawfulness, legitimacy and necessity, in order to achieve the following functions of this policy.</p>
                <p className="fw400">(i) the information you provide to us.</p>
                <p>1. information you submit when you register for an account on our products or services</p>
                <p>When you register or log in to your account, you can create an account by e-mail, you can actively improve the relevant network identification information (such as avatar, nickname and password), you can fill in gender, birthday, impression tags and other information to improve your personal data.</p>
                <p>You can also use a third-party account (such as Google, Facebook, Apple) to log in to our products or services, at which time you will authorize us to obtain your public information on the third-party platform and bind your such third-party account to your account, so that you can log in and use our products and services directly through such third-party account. After completing registration, you will be able to use the corresponding functions on our products as a registered user. If you do not agree to authorize or deny us access to the above information, you may still enjoy the services on our products or services as a guest, but will not be able to enjoy the features and services of other registered users.</p>
                <p>When you use specific products or services (such as live streaming functions or services, payment and cash withdrawals, etc.), you are required to provide your real identity information (real name, ID number) according to relevant national laws and regulations. This information is sensitive personal information, and we will do our best to protect the security of your sensitive personal information. If you do not provide this information, you will not be able to use certain products or services, but it does not affect your enjoyment of the content browsing and registered users of the basic services.</p>

                <p>2. information you fill in or submit when using the relevant services we provide, including your gender, date of birth, email address, interests, relevant additional information (such as your province and city, postal code, etc.).</p>
                <p>Please note that several of our Services allow you to share information about yourself not only with your own social network, but also publicly with all users of the Service, for example, information you upload or post on our platform (including your publicly available personal information, lists you create), your responses to information uploaded or posted by others, and text and image information including those related to such information. Other users of our Services may also share information (including text and image information) about you. As long as you do not delete the shared information, the information will remain in the public domain; even if you delete the shared information, the information may still be independently cached, copied or stored by other users or unaffiliated third parties not under our control, or kept in the public domain by other users or third parties.</p>
                <p>Therefore, please consider carefully the content of the information you upload, post and exchange through our Services. In some cases, you may be able to control the range of users who are entitled to view the information you share through the privacy settings of some of our Services. To request the removal of your relevant information from our Services, please contact us.</p>

                <p className="fw400">(ii) Information collected in the course of your use of the Services.</p>
                <p>In order to better serve you, we may collect information about you, such information includes.</p>
                <p>1. To ensure your normal use of our basic functions and normal services, to improve and optimize our service experience, and to protect your account security, we will automatically receive and record information about your browser, computer, mobile device, including but not limited to your IP address, type of browser, language used, date and time of access, hardware and software characteristics information and your web page records and other data; if you download or If you download or use our or our affiliates' client software, or access mobile web pages to use the services on our platform, we may read information related to your location and mobile device, including but not limited to device model, device identifier, operating system, resolution, telecommunications carrier, etc. Please understand that this information is essential information that we must collect to provide the Services and to ensure the proper functioning of the Services.</p>
                <p>2. We may collect content and information that you upload or post on your own through our Platform, such as text, shared photos, audio and video recordings and the date, time or location of such information. When you use these features, we will ask you to authorize camera, photo, and microphone permissions and device location information. You can choose whether to provide us with this authorization, and if you refuse, you will not be able to use this feature, but it will not affect your use of other features.</p>
                <p>3. In order to provide you with personalized content display and push, and improve user experience, we will collect the following information.</p>
                <p>(1) information about your operation and usage behavior such as attention, search and browsing records; for this purpose, we collect information about your device when you use our products or services, including device model, unique device identifier, operating system, resolution, telecommunication carrier software and hardware information.</p>
                <p>(2) Feedback, posting, likes, comments, rewards and other interactive information that you provide on your own initiative will be stored in our servers.</p>
                <p>(3) The geographic location information after obtaining your express authorization, this information allows us to recommend live content or dynamics of "nearby" users based on your geographic location, and enable your live content or dynamics to be presented on the "nearby" page. Refusal to provide information and permission about the location of your device may affect our ability to accurately locate your location and affect the "Nearby" function, but will not affect your normal use of other functions. In addition, you can also choose to turn on or off the corresponding display through the corresponding product's geolocation control switch.</p>
                <p>Location information is sensitive information, and refusal to provide this information will only prevent you from getting better live services, but will not affect your normal use of other functions.</p>

                <p>4. Provide top-up consumption-related functions or services</p>
                <p>When you use the consumption function of our products, we will collect your top-up records, consumption records information, so that you can check your own transaction records, while protecting your property, virtual property security to the maximum extent possible. The above information is sensitive information, but the collection of the above information is necessary to achieve the relevant functions, otherwise the transaction will not be completed.</p>
                <p>In addition to the above information, in order to improve the quality of our services, we may also collect other information from you, including and not limited to information you provide when you contact our customer service team, information you send to us in response to questionnaires you participate in, and information you provide when you interact with our affiliates and partners. At the same time, in order to improve the security of your use of the relevant services and to more accurately prevent phishing site fraud and Trojan horse viruses, we may determine the risk of your account by understanding some of your Internet usage habits, information about the software you commonly use, etc., and may record some URLs that we consider to be risky.</p>
                <p className="fw400">(iii) Cases in which we obtain your personal information from third parties.</p>
                <p>In order to provide you with better services or to prevent Internet crimes, our affiliates and partners may share your personal information with us as required by law or with your consent. We may obtain account information (avatar, nickname) from third parties that you have authorized to share and, after you agree to this Privacy Policy, tie your third party account to your account so that you can log in and use our products or services directly through your third party account. We will use your personal information in accordance with the agreement with the third party, after confirming the legality of the source of personal information, and in compliance with relevant laws and regulations.</p>

                <p className="bold">II. How we use your information</p>
                <p>In order to improve the quality of our services, we may use your information for the following purposes.</p>
                <p>1. to provide services to you.</p>
                <p>2. for identity verification, customer service, security prevention, fraud monitoring, archiving and backup purposes, and to ensure the security of the products and services we provide to you.</p>
                <p>3. to recommend content that may be of interest to you, including, but not limited to, sending you information about products, services or related advertising (for example, sending commercial short messages to your email address about platform events, promotions, etc.), displaying personalized third-party promotions to you through the system or sharing information with our partners with your consent so that they can send you information about their products and services information about their products and services.</p>
                <p>4. we may combine your personal information with other service information for the purpose of using, sharing or disclosing it for the purpose of providing you with a more personalized use of the service, such as the need to give you a broader social circle.</p>
                <p>5. to allow you to participate in surveys, promotions and sweepstakes about our products and services.</p>
                <p>6. enabling us to better understand how you access and use our services so that we can respond to your individual needs in a targeted manner, such as language settings, location settings, personalized help services and instructions or otherwise respond to you and other users.</p>
                <p>7. software certification or management software upgrades.</p>
                <p>8. to involve you in surveys about our products and services.</p>
                <p>9. other uses as permitted by you.</p>

                <p className="bold">III. How we entrust the processing, sharing, transfer and public disclosure of your information</p>
                <p className="fw400">(i) Entrusted processing</p>
                <p>Some specific modules or functions of the products or services we provide are provided by external suppliers. Companies, organizations and individuals to whom we entrust the processing of personal information will be subject to strict confidentiality agreements requiring them to handle personal information in accordance with our requirements, this Privacy Policy and any other relevant confidentiality and security measures.</p>

                <p className="fw400">(ii) Sharing</p>
                <p><span className="red">*</span> 1. Sharing with Affiliates: Your personal information may be shared with our affiliates and/or their designated service providers in order for us to provide you with products and services based on your account, to recommend information that may be of interest to you, to identify member account anomalies, and to protect the personal and property of our affiliates or other users or the public from harm. We will only share personal information that is necessary and subject to the purposes stated in this Privacy Policy, and will again seek your authorized consent if we share your personally sensitive information or if our affiliates change the purposes for which personal information is used and processed.</p>
                <p><span className="red">*</span> 2. Sharing with Authorized Partners: We may engage authorized partners to provide certain services to you or perform functions on our behalf, and we will only share your information for the lawful, legitimate, necessary, specific, and explicit purposes stated in this Privacy Policy, and authorized partners will only have access to the information they need to perform their duties and will not use this information for any other purpose.</p>
                <p>Currently, our authorized partners include the following categories.</p>
                <p>(1) Authorized partners in the advertising and analytics services category. We do not share your personally identifiable information with partners who provide advertising and analytics services unless we have your permission to do so. We may provide these partners with information about the reach and effectiveness of their ads, but not your personally identifiable information, or we may aggregate this information so that it does not identify you personally. Such partners may combine the above information with other data that they have lawful access to for advertising or decision making recommendations.</p>
                <p>(2) Vendors, Service Providers and Other Partners. We send information to vendors, service providers and other partners who support our business by providing technical infrastructure services, analyzing how our services are used, measuring the effectiveness of advertisements and services, providing customer service, facilitating payments or conducting academic research and surveys.</p>
                <p>We will agree to strict data protection measures with them so that they handle personal information in accordance with our instructions, this Privacy Policy and any other relevant confidentiality and security measures.</p>

                <p className="fw400">(iii) Transfer</p>
                <p>We will not transfer your personal information to any company, organization or individual, except for the following.</p>
                <p><span className="red">*</span> 1. transferring with obtaining explicit consent: we will transfer your personal information to other parties after obtaining your explicit consent.</p>
                <p><span className="red">*</span> 2. to satisfy laws and regulations, legal process requirements or mandatory government requests or judicial decisions.</p>
                <p><span className="red">*</span> 3. if we or our affiliates are involved in a transaction such as a merger, separation, liquidation, acquisition or sale of assets or business, and your personal information may be transferred as part of such transaction, we will ensure the confidentiality of such information at the time of the transfer and require the new company or organization holding your personal information to continue to be bound by this privacy policy, or we will require the company or organization to re-submit to you to seek authorized consent.</p>
                <p className="fw400">(iv) Public Disclosure</p>
                <p>We will only disclose your personal information publicly if we</p>
                <p><span className="red">*</span>1. with your express consent or based on your voluntary choice, we may disclose your personal information publicly.</p>
                <p><span className="red">*</span> 2. If we determine that you have violated laws and regulations or seriously violated the relevant agreements and rules of our products or services, or to protect the personal and property safety of other users or the public from infringement, we may disclose personal information about you in accordance with laws and regulations or with your consent, including the relevant violations and the measures we have taken against you.</p>

                <p className="bold">IV. How we keep and protect your personal information   </p>
                <p className="fw400">(i) The preservation of your personal information</p>
                <p>1. Retention period</p>
                <p>We will continue to store your personal information for you during your use of our products and services. If you cancel your account or voluntarily delete the above information, we will keep your information in accordance with the provisions of the Network Security Law and other laws and regulations. After you cancel your account or voluntarily delete the above information, we will not use your personal information commercially, but we may use your personal information after anonymizing it.</p>
                <p>2. Location of Storage</p>
                <p>Your personal information is stored in a geographic location as permitted by applicable law, for example, for users from mainland China, their personal information is stored on servers located in mainland China. We do not transfer your personal information across borders unless required or permitted by law. If you use technologies that may hide your true geographic location, such as VPN, or encounter objective circumstances such as inaccurate domain name or IP address resolution, you may be connected to a server outside of the above geographic location, at which time your personal information may be transferred across borders.</p>

                <p className="fw400">(ii) Protection of your personal information</p>
                <p>1. In order to safeguard the security of your information, we endeavor to take various reasonable physical, electronic and administrative security measures to protect your information from leakage, destruction or loss, including but not limited to SSL, encrypted storage of information, and data center access control. We also adopt strict management of employees or outsourced personnel who may have access to your information, including but not limited to measures such as adopting different authority controls depending on the position, signing confidentiality agreements with them, and monitoring their operations. We will provide appropriate security measures to protect your information according to the existing technology and provide reasonable security, and we will try our best to make sure that your information will not be leaked, destroyed or lost.</p>
                <p>2. Your account is protected by security features, so please keep your account and password information safe. We will ensure that your information is not lost, misused or altered by backing up to other servers, encrypting your password, and other security measures. Notwithstanding the aforementioned security measures, please understand that there are no "perfect security measures" on the information network. 3.</p>
                <p>3. When using our platform services to conduct online transactions, you will inevitably disclose your personal information, such as bank account information, contact information or postal address, to the counterparty or potential counterparty. Please protect your personal information and provide it to others only when necessary. If you find that your personal information is leaked, especially your account and password, please contact our customer service immediately so that we can take appropriate measures.</p>

                <p className="bold">V. Use of Cookies</p>
                <p>In order to make your access experience easier, when you visit our platform website or use the services provided by our platform, we may identify you through small data files, which are used to help you save the step of repeatedly entering your registration information or to help determine the security of your account. These data files may be cookies, Flash cookies, or other local storage provided by your browser or associated applications (collectively, "Cookies").</p>
                <p>Please understand that some of our services are only available through the use of "cookies". You may modify your acceptance of cookies or refuse our cookies if your browser or browser add-on allows, but this action may, in some cases, affect your ability to safely access websites and use services offered on our Platform.</p>
                <p>Our products and services may have cookies and webbeacons placed by advertisers or other partners. these cookies and webbeacons may collect non-personally identifiable information about you for the purpose of analyzing how users use such services, sending you advertisements that may be of interest to you, or for evaluating the effectiveness of advertising services. The collection and use of such information by these third-party cookies and webbeacons is not governed by this Privacy Policy, but by the privacy policies of the relevant users, and we are not responsible for third-party cookies or webbeacons.</p>
                <p>You can refuse or manage cookies or webbeacons through your browser settings, but please note that if you disable cookies or webbeacons, you may not be able to enjoy a good service experience and some services may not work properly. You will also receive the same number of advertisements, but they will be less relevant to you.</p>

                <p className="bold">VI. Access, Modification and Deletion of Information</p>
                <p>You may access, modify and delete registration information and other personal information that you provide in the course of using our services. The scope and manner of your access, modification and deletion of personal information will depend on the specific service you use.</p>
                <p>We will collect and use your information as described in this policy solely to fulfill the functions of our products or services. If you find that we collect and use your personal information in violation of the laws and administrative regulations or the agreement between the parties, you can ask us to delete it. If you find that we have collected and stored your personal information in error, you can also ask us to correct it.</p>
                <p className="fw400">(i) You can access, correct or delete your information by.</p>
                <p>(1) Logging into the App of the corresponding product or service and accessing, modifying or deleting it after entering the "Personal Center".</p>
                <p>(2) You can contact us through the external announcement mailbox of this platform ({config.contactEmail}). After receiving the email, we will evaluate such requests according to the specific situation.</p>
                <p>(3) You may also submit your request for access, modification or deletion directly here. Upon receipt of the request, we will contact you to understand your specific requirements and meet your needs.<span className="link" onClick={()=>setModalVisible(true)}>(Click here to submit your access, modification, or deletion information)</span></p>

                <p className="fw400">iOS users can request deletion of their data in the following ways.</p>
                <p>(1) Login to the APP, enter [My] - [Settings] - [Privacy Policy], find the clause [VI. Access, Modification and Deletion of Information] and contact us to submit a data deletion request.</p>
                <p>(2) You can also contact us through the platform's external announcement email ({config.contactEmail}). After receiving the email, we will respond to your request according to the specific situation.</p>
                <p>(3) You can also submit your data deletion request directly here. <span className="link" onClick={()=>setModalVisible(true)}>(Click here to submit your data deletion request)</span></p>
                <p className="fw400">(ii) Change the scope of your authorized consent</p>
                <p>Each business function requires some basic personal information in order to be completed. You may give or withdraw your authorized consent from us by changing the settings of your smart mobile devices such as cell phones and tablets or by the means listed above. When you withdraw your consent, we will no longer process the relevant personal information. However, your decision to withdraw your consent will not affect the processing of personal information previously carried out on the basis of your authorization.</p>

                <p className="fw400">(iii) How we respond to your request above</p>
                <p>When you access, modify or delete the relevant information or account, we may ask you to verify your identity in order to protect the security of the account.</p>
                <p>Please understand that due to technical limitations, legal or regulatory requirements, we may not be able to meet all your requests and we will respond to your requests within a reasonable period of time. Also, in accordance with relevant laws and regulations and national standards, we may not be able to respond to your request in the following circumstances.</p>
                <p>1. directly related to national security or national defense security</p>
                <p>2. directly related to public safety, public health, and significant public interests</p>
                <p>3. directly related to crime investigation, prosecution, trial and execution of judgments, etc.</p>
                <p>4. where there is sufficient evidence that you have subjective malice or abuse of rights</p>
                <p>5. where responding to your request will result in serious damage to the legitimate rights and interests of other individuals and organizations</p>
                <p>6. if it involves commercial secrets.</p>

                <p className="bold">VII. Special agreement on the privacy of minors</p>
                <p>We require parents or guardians to instruct minors under the age of eighteen (or other age of majority under applicable law, generally depending on the user's nationality and current country/region) to use our Services after reading this Privacy Policy.</p>
                <p>We do not knowingly collect personal information from minors. Minors should not register for an account or send us personal information such as their name, address, phone number, email address, etc., unless permitted by local law and with the consent of their guardian. If we accidentally collect information from minors, we will delete it as soon as we are aware of it. If you believe that we may improperly hold information about minors, please contact us as provided at the end of this Privacy Policy.</p>

                <p className="bold">VIII. Special Terms of Privacy Policy</p>
                <p>You are fully aware that your authorized consent is not required for us to collect or use personal information in the following circumstances.</p>
                <p>1. related to national security and social public interest</p>
                <p>2. in connection with crime investigation, prosecution, trial and execution of judgment, etc.</p>
                <p>3. for the purpose of safeguarding the life, property and other significant legitimate rights and interests of the subject of the personal information or other individuals but where it is difficult to obtain the consent of the individual.</p>
                <p>4. where the personal information collected is disclosed to the public by the subject of the personal information himself/herself</p>
                <p>5. where your personal information is collected from information that is lawfully and publicly disclosed, such as lawful news reports, government information disclosure and other channels.</p>
                <p>6. the violation of legal provisions or violation of our platform rules lead us to have taken necessary measures against you.</p>
                <p>7. necessary to enter into a contract at your request</p>
                <p>8. necessary to maintain the safe and stable operation of the products or services provided, such as the detection and disposal of product or service failures.</p>
                <p>9. necessary for legitimate press coverage.</p>
                <p>10. necessary for academic research institutions to conduct statistical or academic research in the public interest and to de-identify the personal information contained in the results when providing the results of academic research or descriptions to the public</p>
                <p>11. other circumstances as stipulated by laws and regulations.</p>

                <p className="bold">IX. Changes to the Privacy Policy</p>
                <p>We may revise the terms of this Privacy Policy from time to time, and such revisions will form part of this Privacy Policy. If such revisions result in a material reduction of your rights under this Privacy Policy, we will notify you by prominently displaying the revisions on our home page or by sending you an email or otherwise before the revisions take effect. In such event, by continuing to use our Services, you agree to be bound by this Privacy Policy, as amended.</p>

                <p className="bold">X. How to contact us</p>
                <p>You can contact us through the following ways.</p>
                <p>1. If you have any questions, comments or suggestions about the content of this policy, you can send an email to contact us.</p>
                <p>E-mail: {config.contactEmail}</p>
                <p className="grey">Note: This version of the Privacy Policy was updated on October 29, 2021.</p>
           </div>

        </div>
    )
}
export default Page


